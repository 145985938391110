import React from "react";
import ReactDOM from "react-dom/client";
import { BaSeDialogContainer, BaSeRoot } from "@base/react";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([{ path: "*", element: <App /> }]);

const TemplateRoot = () => {
  return (
    <BaSeRoot locale="pt-BR" withResetCss={true}>
      <RouterProvider router={router} />
      <BaSeDialogContainer />
    </BaSeRoot>
  )
}

if (process.env.REACT_APP_ENV === "development") {
  root.render(
    <React.StrictMode>
      <TemplateRoot />
    </React.StrictMode>
  )
} else {
  root.render(<TemplateRoot />)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
