import "./index.scss";
import logo from "../../assets/images/logo.svg";
import { Bar } from "react-chartjs-2";
import { formatCurrency, principal } from "../../services/utils";
import { useContext, useEffect, useState } from "react";
import { CompaniesContext } from "../../context/companies";
import {
  BaSeHeading2,
  BaSeHeading4,
  BaSeHeading5,
  BaSeHeading6,
  BaSeParagraph,
} from "@base/react";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const PdfPage = ({ dataPlanning }) => {
  const { companies } = useContext(CompaniesContext);
  const [companyName, setCompanyName] = useState();
  const outputData = dataPlanning?.results?.reports?.medians;

  const updateCompanyName = () => {
    if (companies.length) {
      companies.forEach((el) => {
        if (el.isActive && el.cnpj) {
          setCompanyName(el.name);
        }
      });
    }
  };

  useEffect(() => {
    updateCompanyName();
  }, [principal(companies)]);

  const listMont = [
    "jan",
    "fev",
    "mar",
    "abr",
    "mai",
    "jun",
    "jul",
    "ago",
    "set",
    "out",
    "nov",
    "dez",
  ];

  const dataSource =
    dataPlanning?.results?.fluxgraph || dataPlanning?.data?.fluxo?.saidas || [];

  let balanceMap = {};
  let dateChart = [];

  if (dataSource && Array.isArray(dataSource)) {
    dataSource.forEach((item) => {
      const [year, month] = item.mes.split("-");
      const formattedDate = `${listMont[parseInt(month) - 1]}-${year.slice(
        2,
        4
      )}`;

      const totalValue = item.entries
        ? item.entries.reduce((sum, entry) => sum + (entry.val || 0), 0)
        : item.capacidadeMensal || 0;

      balanceMap[formattedDate] = totalValue;
    });
  }

  if (dataSource.length > 0) {
    const [startYear, startMonth] = dataSource[0].mes.split("-").map(Number);

    const [endYear, endMonth] = dataSource[dataSource.length - 1].mes
      .split("-")
      .map(Number);

    const calculatedMonths =
      (endYear - startYear) * 12 + (endMonth - startMonth) + 1;

    for (let i = 0; i < calculatedMonths; i++) {
      const currentMonth = (startMonth - 1 + i) % 12;
      const currentYear = startYear + Math.floor((startMonth - 1 + i) / 12);
      const formattedDate = `${listMont[currentMonth]}-${currentYear
        .toString()
        .slice(2, 4)}`;
      dateChart.push(formattedDate);
    }
  }

  const balance = dateChart.map((date) => balanceMap[date] || 0);

  const data = {
    labels: dateChart,
    datasets: [
      {
        label: "Variação",
        data: balance,
        backgroundColor: balance.map((value) =>
          value >= 0 ? "#14ADCC" : "#F4455A"
        ),
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
    },
    scales: {
      x: {
        ticks: {
          color: "#005EB8",
          maxRotation: 90,
          minRotation: 90,
        },
        grid: { display: false },
      },
      y: {
        ticks: {
          color: "#005EB8",
          callback: (val) => {
            return "R$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          },
        },
        grid: { color: "rgba(148, 252, 204, 0.2)" },
      },
    },
    layout: { padding: 10 },
  };

  return (
    <>
      <div id="pdf-content" className="pdf-container">
        <section>
          <div className="pdf-header">
            <div className="pdf-img-planejadora-sebrae"></div>
            <span className="pdf-header-title">
              <BaSeHeading2 isBold> {dataPlanning?.title} </BaSeHeading2>
              <h6>Planejamento Financeiro Empresarial</h6>
            </span>
            <img src={logo} alt="Logo-sebrae" />
          </div>
          <div className="pdf-company-details">
            <BaSeHeading6>{companyName}</BaSeHeading6>
            <BaSeHeading6>
              Data do planejamento:{" "}
              {moment(dataPlanning?.created_at).format("DD/MM/YYYY")}
            </BaSeHeading6>
            <BaSeHeading6 color="#DA0063">
              Data da meta para alcance dos resultados projetados:{" "}
              {moment(dataPlanning?.data?.goal_date).format("DD/MM/YYYY")}
            </BaSeHeading6>
          </div>
          <div className="pdf-sub-header">
            <BaSeHeading4 isBold>Resultados</BaSeHeading4>
          </div>
          <BaSeHeading6 className="pdf-title" color="#005EB8" isBold>
            Resultados
          </BaSeHeading6>
          <div className="pdf-results-information">
            <div className="pdf-results-information__data">
              <span className="pdf-results-information__info">
                <BaSeParagraph isBold>Receita Total (RT)</BaSeParagraph>
              </span>
              <span className="pdf-results-information__value">
                <BaSeParagraph>
                  R$ {formatCurrency(dataPlanning?.results?.rt)}
                </BaSeParagraph>
              </span>
            </div>
            <div className="pdf-results-information__data">
              <span className="pdf-results-information__info">
                <BaSeParagraph isBold>Custo Total (CT)</BaSeParagraph>
              </span>
              <span className="pdf-results-information__value">
                <BaSeParagraph>
                  R$ {formatCurrency(dataPlanning?.results?.ct)}
                </BaSeParagraph>
              </span>
            </div>
            <div className="pdf-results-information__data">
              <span className="pdf-results-information__info">
                <BaSeParagraph isBold>Lucro (L)</BaSeParagraph>
              </span>
              <span className="pdf-results-information__value highlighted">
                <BaSeParagraph
                  color={
                    dataPlanning?.results?.l === 0
                      ? "#005EB8"
                      : dataPlanning?.results?.l > 0
                      ? "#008000"
                      : "#BF303C"
                  }
                  isBold
                >
                  R$ {formatCurrency(dataPlanning?.results?.l)}
                </BaSeParagraph>
              </span>
            </div>
            <div className="pdf-results-information__data">
              <span className="pdf-results-information__info">
                <BaSeParagraph color="#005EB8" isBold>
                  Lucratividade (%LT)
                </BaSeParagraph>
              </span>
              <span className="pdf-results-information__value highlighted">
                <BaSeParagraph
                  color={
                    dataPlanning?.results?.lt === 0
                      ? "#005EB8"
                      : dataPlanning?.results?.lt > 0
                      ? "#008000"
                      : "#BF303C"
                  }
                  isBold
                >
                  {formatCurrency(dataPlanning?.results?.lt)}%
                </BaSeParagraph>
              </span>
            </div>
          </div>
          <BaSeHeading6 className="pdf-title" color="#005EB8" isBold>
            Investimento
          </BaSeHeading6>

          <div className="pdf-results-information">
            <div className="pdf-results-information__data">
              <span className="pdf-results-information__info">
                <BaSeParagraph isBold>Investimento Financeiro</BaSeParagraph>
              </span>
              <span className="pdf-results-information__value">
                <BaSeParagraph>
                  R$ {formatCurrency(dataPlanning?.results?.infin)}
                </BaSeParagraph>
              </span>
            </div>
            <div className="pdf-results-information__data">
              <span className="pdf-results-information__info">
                <BaSeParagraph isBold>Investimento Total</BaSeParagraph>
              </span>
              <span className="pdf-results-information__value">
                <BaSeParagraph>
                  R$ {formatCurrency(dataPlanning?.results?.intot)}
                </BaSeParagraph>
              </span>
            </div>
            <div className="pdf-results-information__data">
              <span className="pdf-results-information__info">
                <BaSeParagraph color="#005EB8" isBold>
                  Rentabilidade (mensal)
                </BaSeParagraph>
              </span>
              <span className="pdf-results-information__value highlighted">
                <BaSeParagraph
                  color={
                    dataPlanning?.results?.rtm === 0
                      ? "#005EB8"
                      : dataPlanning?.results?.rtm > 0
                      ? "#008000"
                      : "#BF303C"
                  }
                  isBold
                >
                  {formatCurrency(dataPlanning?.results?.rtm)}%
                </BaSeParagraph>
              </span>
            </div>
            <div className="pdf-results-information__data">
              <span className="pdf-results-information__info">
                <BaSeParagraph color="#005EB8" isBold>
                  Prazo de Retorno de Investimentos (anual)
                </BaSeParagraph>
              </span>
              <span className="pdf-results-information__value highlighted">
                <BaSeParagraph
                  color={
                    dataPlanning?.results?.pri === 0
                      ? "#005EB8"
                      : dataPlanning?.results?.pri > 0
                      ? "#008000"
                      : "#BF303C"
                  }
                  isBold
                >
                  {formatCurrency(dataPlanning?.results?.pri)}
                </BaSeParagraph>
              </span>
            </div>
          </div>

          <BaSeHeading6 className="pdf-title" color="#005EB8" isBold>
            Capital de giro
          </BaSeHeading6>

          <div className="pdf-results-information">
            <div className="pdf-results-information__data">
              <span className="pdf-results-information__info">
                <BaSeParagraph isBold>
                  Necessidade de Capital de Giro
                </BaSeParagraph>
              </span>
              <span className="pdf-results-information__value">
                <BaSeParagraph>
                  R$ {formatCurrency(dataPlanning?.results?.ncg)}
                </BaSeParagraph>
              </span>
            </div>
            <div className="pdf-results-information__data">
              <span className="pdf-results-information__info">
                <BaSeParagraph isBold>Capital de giro próprio</BaSeParagraph>
              </span>
              <span className="pdf-results-information__value">
                <BaSeParagraph>
                  R$ {formatCurrency(dataPlanning?.results?.cgp)}
                </BaSeParagraph>
              </span>
            </div>
            <div className="pdf-results-information__data">
              <span className="pdf-results-information__info">
                <BaSeParagraph color="#005EB8" isBold>
                  Necessidade de Formação de Capital de Giro
                </BaSeParagraph>
              </span>
              <span className="pdf-results-information__value highlighted">
                <BaSeParagraph
                  color={
                    dataPlanning?.results?.nfcg === 0
                      ? "#005EB8"
                      : dataPlanning?.results?.nfcg > 0
                      ? "#008000"
                      : "#BF303C"
                  }
                  isBold
                >
                  R${" "}
                  {formatCurrency(
                    dataPlanning?.results?.nfcg >= 0
                      ? dataPlanning?.results?.nfcg
                      : 0
                  )}
                </BaSeParagraph>
              </span>
            </div>
          </div>
          {dataPlanning?.results?.nfcg <= 0 && (
            <span className="pdf-message-observation">
              <BaSeParagraph isThin>
                Não há necessidade de Formação de Capital de Giro
              </BaSeParagraph>
            </span>
          )}
        </section>
        <section>
          <div className="pdf-header">
            <div className="pdf-img-planejadora-sebrae"></div>
            <span className="pdf-header-title">
              <BaSeHeading2 isBold> {dataPlanning?.title} </BaSeHeading2>
              <h6>Planejamento Financeiro Empresarial</h6>
            </span>
            <img src={logo} alt="Logo-sebrae" />
          </div>
          <div className="pdf-company-details">
            <BaSeHeading6>{companyName}</BaSeHeading6>
            <BaSeHeading6>
              Data do planejamento:{" "}
              {moment(dataPlanning?.created_at).format("DD/MM/YYYY")}
            </BaSeHeading6>
          </div>
          <div className="pdf-sub-header">
            <BaSeHeading4 isBold>Resultados</BaSeHeading4>
          </div>
          <div className="pdf-inputs">
            <div>
              <BaSeParagraph>
                Aumento geral das quantidades vendidas
              </BaSeParagraph>
              <div className="pdf-text-inputs">
                <BaSeHeading6>
                  %{" "}
                  {dataPlanning?.data?.VGdQV
                    ? formatCurrency(dataPlanning?.data?.VGdQV)
                    : "0,00"}
                </BaSeHeading6>
              </div>
            </div>

            <div>
              <BaSeParagraph>
                Descreva a justificativa para aumento geral das quantidades
                vendidas
              </BaSeParagraph>
              <div className="pdf-text-area">
                <BaSeHeading6>
                  {dataPlanning?.data?.VGdQV_just
                    ? dataPlanning?.data?.VGdQV_just
                    : "Sua justificativa"}
                </BaSeHeading6>
              </div>
            </div>
            <div>
              <BaSeParagraph>Aumento geral de preços de venda</BaSeParagraph>
              <div className="pdf-text-inputs">
                <BaSeHeading6>
                  %{" "}
                  {dataPlanning?.data?.VGdPV
                    ? formatCurrency(dataPlanning?.data?.VGdPV)
                    : "0,00"}
                </BaSeHeading6>
              </div>
            </div>
            <div>
              <BaSeParagraph>
                Descreva a justificativa para aumento geral de preços de venda
              </BaSeParagraph>
              <div className="pdf-text-area">
                <BaSeHeading6>
                  {dataPlanning?.data?.VGdPV_just
                    ? dataPlanning?.data?.VGdPV_just
                    : "Sua justificativa"}
                </BaSeHeading6>
              </div>
            </div>

            <div className="pdf-results-observation">
              Seu planejamento deve ser um plano vivo o qual você consulta e
              revisa. Acesse sempre que necessário a Planejadora para inserir
              novos planos no decorrer dos projetos empresariais. <br />
              Lembre-se, você se comprometeu a alcançar esses resultados em{" "}
              {moment(dataPlanning?.data?.goal_date).format("DD/MM/YYYY")}.
              Persiga as estratégias que considerou no planejamento, envolvendo:
              <ul>
                <li>- Variações nas vendas e preço e ações associadas;</li>
                <li>- Mudanças nas estruturas de custos;</li>
                <li>- Realização de investimentos.</li>
              </ul>
            </div>
          </div>
        </section>
        {dataPlanning?.results?.fluxgraph &&
          Object.keys(dataPlanning?.results?.fluxgraph).length > 0 && (
            <section>
              <div className="pdf-header">
                <div className="pdf-img-planejadora-sebrae"></div>
                <span className="pdf-header-title">
                  <BaSeHeading2 isBold> {dataPlanning?.title} </BaSeHeading2>
                  <h6>Planejamento Financeiro Empresarial</h6>
                </span>
                <img src={logo} alt="Logo-sebrae" />
              </div>
              <div className="pdf-company-details">
                <BaSeHeading6>{companyName}</BaSeHeading6>
                <BaSeHeading6>
                  Data do planejamento:{" "}
                  {moment(dataPlanning?.created_at).format("DD/MM/YYYY")}
                </BaSeHeading6>
              </div>
              <div className="pdf-sub-header">
                <BaSeHeading4 isBold>
                  Capacidade mensal de pagamento projetada
                </BaSeHeading4>
              </div>
              <div style={{ paddingTop: "15px" }}>
                <BaSeHeading5>
                  Entradas:{" "}
                  <strong
                    style={{
                      color:
                        dataPlanning?.data?.fluxo?.saldoInicial > 0
                          ? "#005EB8"
                          : "#BF303C",
                    }}
                  >
                    {`R$ ` +
                      formatCurrency(dataPlanning?.data?.fluxo?.saldoInicial)}
                  </strong>
                </BaSeHeading5>
              </div>

              <BaSeHeading5>Saídas:</BaSeHeading5>

              <div>
                {outputData && (
                  <>
                    <div className="pdf-output-data-header">
                      <div></div>
                      <div></div>
                      <p>Média Mensal</p>
                    </div>
                    {Object.entries(outputData).map(([name, details]) => (
                      <div key={name} className="pdf-output-data">
                        <h3>{name}</h3>
                        <p>{details.intervalo}</p>
                        <p style={{ color: "#BF303C" }}>
                          {`R$ ` + formatCurrency(details.media_mensal)}
                        </p>
                      </div>
                    ))}
                  </>
                )}
              </div>
              <br />
              <br />
              <BaSeHeading5>
                Valor total apurado da média mensal:{" "}
                <strong style={{ color: "#BF303C" }}>
                  {"R$ " +
                    formatCurrency(dataPlanning?.results?.reports?.total)}
                </strong>
              </BaSeHeading5>
              <br />
              <hr />
              <br />
              <BaSeHeading5>
                Capacidade de pagamento livre médio mensal:{" "}
                {dataPlanning?.results?.reports?.difference > 0 ? (
                  <strong style={{ color: "#005EB8" }}>
                    {"R$ " +
                      formatCurrency(
                        dataPlanning?.results?.reports?.difference
                      )}
                  </strong>
                ) : (
                  <strong style={{ color: "#BF303C" }}>
                    {"R$ " +
                      formatCurrency(
                        dataPlanning?.results?.reports?.difference
                      )}
                  </strong>
                )}
              </BaSeHeading5>
              {/* <BaSeHeading5>
                Capacidade de pagamento livre médio mensal: R${" "}
                {formatCurrency(
                  dataPlanning?.data?.fluxo?.saldoInicial -
                    dataPlanning?.results?.reports?.total
                )}
              </BaSeHeading5> */}
              <br />
              <br />
              <div className="chart-area__data">
                <Bar data={data} options={options} />
              </div>
            </section>
          )}
        <section>
          <div className="pdf-header">
            <div className="pdf-img-planejadora-sebrae"></div>
            <span className="pdf-header-title">
              <BaSeHeading2 isBold> {dataPlanning?.title} </BaSeHeading2>
              <h6>Planejamento Financeiro Empresarial</h6>
            </span>
            <img src={logo} alt="Logo-sebrae" />
          </div>
          <div className="pdf-company-details">
            <BaSeHeading6>{companyName}</BaSeHeading6>
            <BaSeHeading6>
              Data do planejamento:{" "}
              {moment(dataPlanning?.created_at).format("DD/MM/YYYY")}
            </BaSeHeading6>
          </div>
          <div className="pdf-sub-header">
            <BaSeHeading4 isBold>IMPORTANTE</BaSeHeading4>
          </div>
          <div style={{ paddingTop: "30px" }}>
            <BaSeParagraph>
              <strong>NOTA TÉCNICA 1:</strong> <br />
              Se os dados lançados nesta calculadora estiverem incoerentes com a
              realidade da empresa, o planejamento elaborado não deverá ser
              utilizado para basear decisões estratégicas e nem balizar as
              negociações relacionadas a contratação de crédito.
            </BaSeParagraph>
            <br />
            <BaSeParagraph>
              <strong>NOTA TÉCNICA 2:</strong> <br />O dimensionamento da
              Necessidade de Capital de Giro, contido nesta calculadora, visa
              fundamentar decisões sobre política de prazos e investimentos em
              capital de giro e utiliza estimativas para valores como vendas,
              prazos, custos e estoque. <br /> <br />
              Se estas estimativas estiverem incoerentes com a realidade da
              empresa no mercado, a Necessidade de Capital de Giro, apresentada
              na tela denominada Resultado, não representará, necessariamente, o
              valor a ser pleiteado como crédito em uma instituição financeira
              (banco).
            </BaSeParagraph>
            <br />
            <BaSeParagraph>
              <strong>NOTA TÉCNICA 3:</strong> <br />O método de dimensionamento
              da Necessidade de Capital de Giro, contido nesta calculadora tem,
              fundamentalmente, caráter orientativo. Visa ampliar a compreensão
              sobre as finanças da empresa e possibilita realizar simulações que
              demostrem como a Necessidade de Capital de Giro muda de valor
              quando variamos as vendas ou os prazos médios de recebimento, de
              pagamento e das mercadorias em estoque.
            </BaSeParagraph>
          </div>
        </section>
      </div>
    </>
  );
};

export default PdfPage;
