import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BaSeButton, BaSeHeading4, BaSeHeading6, BaSeIcon } from "@base/react";
import { MenuItems, ProfileItems } from "../../../mocks/menu";
import Button from "../../../components/button/button";
import logo from "../../../assets/images/logo.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/images/icons/arrow-hover-right-white.svg";
import FloatingMenu from "../../../components/floating-menu";
import Acronyms from "../../acronyms";
import { CompaniesContext } from "../../../context/companies";
import ModalDefault from "../../modals/modal-default";
import { principal } from "../../../services/utils";
import ApiPlannings from "../../../services/planning";
import ApiAuth from "../../../services/auth";
import "./index.scss";

const api = ApiPlannings();

export default function Header({ account, setAccount }) {
  const { pathname } = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuDeskOpen, setMenuDeskOpen] = useState(false);
  const { companies } = useContext(CompaniesContext);
  const [isModalOpen, setModalOpen] = useState(false);
  const currentYear = new Date().getFullYear();

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const getActivePlanning = () => {
    api
      .getPlannings(principal(companies))
      .then((response) => {
        const { data } = response;

        const actualList = data.filter((item) => !item.in_trashcan);

        const plansThisYear = actualList.filter(
          (plan) => new Date(plan.created_at).getFullYear() === currentYear
        );
        setAccount({
          ...account,
          activePlanning: plansThisYear.length,
        });
      })
      .catch((err) => {
        console.warn("ERROR => ", err);
      });
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleLinkClick = (e, id) => {
    if (id === 2 && account?.activePlanning >= 120) {
      e.preventDefault();
      handleOpenModal();
    }
  };

  const actionMenu = () => {
    setMenuOpen(!menuOpen);
    document.body.style.overflow = !menuOpen ? "hidden" : "";
  };
  const logout = () => {
    ApiAuth().logout();
  };

  const path =
    pathname.includes("/planejamento") ||
    pathname.includes("/meus-planejamentos") ||
    pathname.includes("/conteudo") ||
    pathname.includes("/pilula") ||
    pathname.includes("/planejadora-sebrae") ||
    pathname.includes("/como-utilizar");

  useEffect(() => {
    getActivePlanning();
    // eslint-disable-next-line
  }, [companies]);

  return (
    <header id="header">
      <ModalDefault
        title="Limite Máximo de Planejamentos"
        callBackClose={handleCloseModal}
        show={isModalOpen}
      >
        <BaSeHeading6>
          Você atingiu o limite máximo de <strong>120 planejamentos</strong>{" "}
          para o ano de {currentYear}.{" "}
        </BaSeHeading6>
        <br />
        <BaSeHeading6>
          Para adicionar novos planejamentos, por favor, exclua um planejamento
          já existente.
        </BaSeHeading6>
        <br />

        <BaSeButton value="Ok" onClick={handleCloseModal} />
      </ModalDefault>
      <div className="container">
        <nav>
          <div className="wrapper-menu">
            <button
              className={`btn btn-menu btn-without-style ${
                menuOpen ? "active" : ""
              }`}
              onClick={() => {
                actionMenu();
              }}
            >
              <BaSeIcon
                color="#005EB8"
                description="Menu"
                name="bars"
                size={24}
              />
            </button>
            <div className={`list-menu ${menuOpen ? "open" : ""}`}>
              <div className="content">
                <div>
                  <div className="close" onClick={() => actionMenu()}></div>
                  <div className="title-menu">
                    <Link to={path ? "/meus-planejamentos/" : null}>
                      <h3 onClick={() => setMenuOpen(false)}>
                        Planejamento
                        <ArrowIcon />
                      </h3>
                    </Link>
                  </div>
                  {path && (
                    <ul>
                      {" "}
                      {MenuItems.map((item, k) => (
                        <li
                          key={k}
                          className={`${
                            pathname === item.path ? "active" : ""
                          }`}
                        >
                          {" "}
                          <Link
                            to={
                              item.id === 1 && companies.length
                                ? "/planejadora-sebrae"
                                : item.path
                            }
                            onClick={(e) => handleLinkClick(e, item.id)}
                          >
                            {" "}
                            <span>{item.name}</span> {item.icon}{" "}
                          </Link>{" "}
                        </li>
                      ))}{" "}
                    </ul>
                  )}
                </div>
                <div className="profile-menu">
                  <div
                    className="icon-profile"
                    onClick={() => setMenuDeskOpen(!menuDeskOpen)}
                  >
                    <Acronyms nameUser={account.name} />
                  </div>
                  <ul
                    className={`floating-data ${
                      menuDeskOpen ? "profile-menu-open" : ""
                    }`}
                  >
                    <li>
                      <BaSeHeading4>
                        <span>{account.name}</span>
                        <span className="mid">{account.email}</span>
                      </BaSeHeading4>
                    </li>
                    {ProfileItems.map((item, k) => (
                      <li key={k}>
                        <a
                          target={item.target ? "_blank" : "_self"}
                          href={item.path}
                          rel="noreferrer"
                        >
                          <span>{item.name}</span>
                        </a>
                      </li>
                    ))}
                    <li>
                      <Button callback={() => logout()} title="Sair" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Link to="/">
            <img src={logo} className="logo" alt="logo" />
          </Link>
          {pathname !== "/" && <FloatingMenu />}
        </nav>
      </div>
    </header>
  );
}
